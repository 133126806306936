import { HttpClient } from 'adapters/httpClient'
import { StreamShopStatusResponse } from './streamshop.types'

export class StreamShopService {
  constructor(private readonly httpClient = new HttpClient()) {}

  async getStreamShopStatus(): Promise<boolean> {
    try {
      const { body, error } =
        await this.httpClient.request<StreamShopStatusResponse>({
          method: 'get',
          url: `${process.env.NEXT_PUBLIC_SERVICES_PUB}/notice/v1/streamshop`
        })

      if (!body || error) {
        console.warn('Streamshop status request failed')
        return false
      }

      return body.streamshop_live
    } catch (e) {
      console.error('Unexpected error fetching streamshop status')
      return false
    }
  }
}
