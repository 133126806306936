import { useEffect, useState } from 'react'
import { StreamShopService } from 'services/streamshop'
function useStreamShopStatus() {
  const [streamShopActive, setStreamShopActive] = useState(false)

  useEffect(() => {
    async function fetchStatus() {
      try {
        const streamShopService = new StreamShopService()
        const status = await streamShopService.getStreamShopStatus()
        setStreamShopActive(status)
      } catch (error) {
        console.error('Erro ao buscar status do Stream Shop:', error)
        setStreamShopActive(false)
      }
    }

    fetchStatus()
  }, [])

  return streamShopActive
}

export default useStreamShopStatus
